export const seo = {
  "home": {
    "pageName": "首页",
    "title": "杭州润达科技信息有限公司-公司服务介绍-品牌故事-润达科技-润达科技信息",
    "keywords": "杭州润达科技新有限公司提供软件定制研发，各种产品设计，以及项目外包服务，技术人员外包服务",
    "description": "润达科技信息有限公司本着专业奋斗，开发创新与诚信合作的态度，用专业的技术和服务赢得客户的信赖与长期合作，用创新与技术推动商业发展和社会进步，同时我们还秉承互联网时代开放创新精神，构筑企业互联网服务生态圈，为企业提供公共组织软件服务、技术支持和网络运营的提供商。"
  },
  "introduce": {
    "pageName": "企业简介",
    "title": "杭州润达科技信息有限公司-润达科技官网-杭州润达科技官网-润达公司简介",
    "keywords": "杭州润达科技信息有限公司，虽然我们是一家刚成立的公司，但是我们的团队成员都是经历过各种互联网领域与行业的历练，拥有强大的创意设计能力，熟悉各种开发技术语言，为客户提供量身定做的高端网站建设，怀揣仁爱之心，做品质服务。",
    "description": "杭州润达科技信息有限公司创立初期，公司技术团队有30多人，分别为项目经理、产品、UI设计、开发等。各种手机端与电脑端应用页面设计，对于手机端微信小程序、支付宝小程序、手机APP软件定制开发，包括各种政府应用中嵌套的手机端网站与系统开发，PC端网站与系统的建设，用我们专业的技术，做品质的服务。"
  },
  "service": {
    "pageName": "润达服务",
    "title": "杭州润达科技信息有限公司-杭州润达科技服务-杭州润达科技信息服务-润达服务",
    "keywords": "规划+创意+技术，润达科技的优势，解决方案",
    "description": "你有需求，我们来为你规划，你有规划，我们来实现，专业的产品设计能把你的梦想变成想法，想法在变成现实，根据您的需求，创意您的设计，实现您的所需。"
  },
  "rdCase": {
    "pageName": "润达案例",
    "title": "杭州润达科技信息有限公司-润达案例-润达科技信息案例",
    "keywords": "创造需求，打造品牌",
    "description": "企业官网，门户网站，后台管理系统，小程序，HTML手机端，App，物联网，校园网站，多个领域行业。"
  },
  "about": {
    "pageName": "关于我们",
    "title": "杭州润达科技信息有限公司-润达科技信息-润达关于我们-关于我们",
    "keywords": "关于我们，图片展示，团队展示，我们的团队介绍。",
    "description": "杭州润达科技信息有限公司，润达团队信息展示，润达品牌信息展示，关于我们。"
  },
  "concatUs": {
    "pageName": "联系我们",
    "title": "润达地址-联系方式-地址-邮箱-杭州润达科技信息有限公司-润达联系方式",
    "keywords": "慧拨,电销系统,打电话的软件,电话销售呼叫系统,用软件拨号",
    "description": "地址：浙江省杭州市余杭区星运数字创造中心5幢1302室，微信：mituo456321 冯先生，QQ：596923143 黄先生，邮箱：huangrunbin_accp@163.com"
  }
  
}