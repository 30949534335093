<template>
  <div class="footer">
    <div class="contact">
      <img src="../assets/img/phone.png" alt="">
      <div class="hotline">
        <span>服务热线</span>
        <span>冯总：17731140281</span>
      </div>
    </div>
    <p><a class="copyright" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备2023021263号-1  杭州润达科技信息有限公司</a></p>
  </div>
</template>

<style lang="less" scoped>
.footer{
  background-color: #637283;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  .copyright {
    color: #fff;
  }
}
.contact{
  display: flex;
  align-items: center;
  img{
    margin-right: 12px;
  }
  .hotline{
    span{
      display: block;
      font-size: 14px;
      text-align: left;
    }
    span:last-child{
      font-size: 14px;
    }
  }
}
</style>