<template>
  <metainfo>
    <template v-slot:title="{ content }">`SITE_NAME`</template>
  </metainfo>
  <div id="app">
    <Header />
    <!-- <div style="height: 506px;"></div> -->
    <a-config-provider :locale="locale">
      <router-view/>
    </a-config-provider>
    <Footer />
    <div class="sidepop">
      <div @click="followUs">关注我们</div>
      <div @mousemove="handleMove">在线咨询
        <div class="codeBox">
          <p class="p1">扫码关注我们<br/>获取更多资讯</p>
          <img src="../src/assets/img/code.png" alt="">
          <p class="p2">请使用微信</p>
          <img src="../src/assets/img/wx.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import {seo} from '@/assets/seo.js'
import {ref,onMounted} from 'vue'
import {useRouter} from 'vue-router'
const  router = useRouter()
const locale = ref(zhCN)
const isMobile = ()=>{
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

 

onMounted(()=>{
  
  let hostname = window.location.hostname;
  if (isMobile()) {
    if (hostname) {
      if (hostname.indexOf("www.rundakj.com") > -1) {
        window.location.href = "http://h.rundakj.com";
      }
    }
  }
})
const isShow = ref(false)
const handleMove = ()=>{
  isShow.value = true
}
const followUs = ()=>{
  router.push({path:'/aboutUs'})
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.sidepop{
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  font-size: 14px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.1);
  border-radius: 6px;
  width: 72px;
  .codeBox{
    width: 154px;
    height: 246px;
    background: #fff;
    box-shadow: 0px 6px 11px 2px rgba(0,0,0,0.1);
    opacity: 0.7;
    border-radius: 8px;
    position: absolute;
      left: -174px;
      top:-90px;
    .p1{
      color: #333E55;
      font-size: 16px;
      font-weight: bold;
      padding-top: 10px;
      margin-bottom: 10px;
    }
    .p2{
      color: #000;
      font-size: 14px;
      margin-bottom: 8px;
      margin-top: 10px;
    }
  }
  &>div{
    display: block;
    padding: 28px 20px;
    color: #999;
    cursor: pointer;
  }
  &>div:last-child{
    background: linear-gradient(180deg, #1C79E1, #88C0FF);
    border-radius: 0px 0px 4px 4px;
    color: #fff;
    position: relative;
    .codeBox{
      display: none;
    }
  }
  &>div:last-child:hover{
    .codeBox{
      display: block;
    }
  }
  
}
</style>
