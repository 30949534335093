<template>
  <div style="position: relative;">
    <!-- <div class="shadow"></div> -->
    <div class="bt">
      <div class="header w1200">
        <div class="logo"><img src="../assets/logo.png" alt=""></div>
        <ul class="menulist">
          <li v-for="(item,index) in menulist"
          :class="{ active: current[index] }"
            :key="index"
            @mousemove="handleMove(item, index)"
            @click="handleClick(item, index)">
            <span>{{ item.name }}</span>
            <!-- 二级 -->
            <!-- <div class="sub-menu sub-List" v-if="item.subMenuList &&
                item.subMenuList.length > 0 && isShowSubMenu[index]"
                @mouseleave="leaveSubmenu">
              <ul>
                <li v-for="(it, ind) in item.subMenuList" :key="ind">
                  <router-link :to="it.path">{{ it.name }}</router-link>
                </li>
              </ul>
            </div> -->
          </li>
        </ul>
      </div>
      <div class="line"></div>
    </div>
    <!-- 轮播banner -->
    <a-carousel autoplay>
      <div v-for="(item, index) in banners" :key="index">
        <div :style="{ 'background-image': 'url(' + item.url + ')' }" class="swiper">
        </div>
      </div>
    </a-carousel>
  </div>
</template>
<script setup>
import {ref,reactive,watch} from 'vue'
import {useRouter,useRoute} from 'vue-router'
const  router = useRouter()
const route = useRoute()
const banners = [
  {
    title: "banner1",
    url: require("../assets/img/banner1.jpg")
  },
  {
    title: "banner2",
    url: require("../assets/img/banner2.jpg")
  },
  {
    title: "banner3",
    url: require("../assets/img/banner3.jpg")
  }
]
const menulist = [
  {
    name: "官方首页",
    path: "/",
  },
  {
    name: "企业简介",
    path: "/introduction",
  },
  {
    name: "润达服务",
    path: "/service",
  },
  {
    name: "润达案例",
    path: "/case/list",
  },
  {
    name: "关于我们",
    path: "/aboutUs",
  },
  {
    name: "联系我们",
    path: "/contactUs",
  },
  // {
  //   index: "6",
  //   name: "案列",
  //   path: "/case",
  //   subMenuList:[
  //     {
  //       name: "案例1",
  //       path: "/case2/case1"
  //     }
  //   ]
  // }
]

const current = ref(Array(menulist.length).fill(false));
current.value[0] = true;
const handleClick = (item,index)=>{
  current.value = Array(menulist.length).fill(false);
	current.value[index] = true;
  if (item.path === "/case2") {
    return;
  }
  router.push({ path: item.path });
}
watch(()=>router.currentRoute.value.path, (newVal, oldVal) => {
  console.log(newVal,'newVal',router.currentRoute.value.path)
  current.value = ref(Array(menulist.length).fill(false));
  if(newVal.indexOf('introduction')>-1){
    current.value[1] = true;
  }
  else if(newVal.indexOf('service')>-1){
    current.value[2] = true;
  }
  else if(newVal.indexOf('case')>-1){
    console.log('进这里')
    current.value[3] = true;
  }
  else if(newVal.indexOf('aboutUs')>-1){
    current.value[4] = true;
  }
  else if(newVal.indexOf('contactUs')>-1){
    current.value[5] = true;
  }
  // else if(newVal.indexOf('case')>-1){
  //   current.value[5] = true;
  // }
  else{
    current.value[0] = true;
  }
  }, { immediate: true })
  // 上移一级菜单显示二级
const isShowSubMenu = ref(Array(menulist.length).fill(false));
const handleMove = (item,index)=>{
  isShowSubMenu.value = ref(Array(menulist.length).fill(false));
  isShowSubMenu.value[index] = true
}
//离开子菜单
const leaveSubmenu = ()=>{
  isShowSubMenu.value = ref(Array(menulist.length).fill(false));
}
</script>
<style lang="less" scoped>
.swiper {
  height: 506px;
  background-position: center;
  background-repeat: no-repeat;
}
.shadow{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
    width: 506px;
  	height: 506px;
  	background-repeat: no-repeat;
    background: linear-gradient(45deg, rgba(7,26,210,0.7) 0%, rgba(7,26,210,0) 100%);
}
.bt{
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  .line{
    height: 2px;
    background: #fff;
    opacity: 0.2;
  }
}
.header{
  display: flex;
  justify-content: space-between;
}
.w1200{
  width: 1200px;
  margin: 0 auto;
}
.logo img{
  margin-top: 20px;
}
.menulist{
    display: flex;
    width: 600px;
    justify-content: space-between;
    margin-bottom: 0;
    li{
      cursor: pointer;
      padding-top: 20px;
      color: #fff;
      position: relative;
      span{
        display: block;
        box-sizing: border-box;
        border-radius:4px 4px 0 0;
        width: 110px;
        height: 60px;
        line-height: 60px;
      }
    }
    li:hover,li.active {
		span {
			background-color: rgba(20,38,117,0.4);
      font-weight: bold;
      color: #fff;
		}
	}
  }
.sub-menu{
  position: absolute;
	top: 80px;
	left: 0;
	background: #fff;
	box-shadow: 0px 1px 4px 1px #ddd;
	border-radius: 5px;
	z-index: 100;
  width: 100px;
  height: 215px;
  a {
		color: #333;
	}
	a:hover {
		color: #1890ff;
	}
  ul{
    padding: 0;
  }
  li{
    padding: 0;
    height: 40px;
      line-height: 40px;
      background: #fff;
  }
  li:hover{
    background: #e0e8f5;
			a {
				color: #3a5ed1;
			}
  }
}
</style>